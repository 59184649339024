define("discourse/plugins/chat/discourse/components/chat/message-creator/new-group", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/members-count", "discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _service, _truthHelpers, _dButton, _ajaxError, _discourseI18n, _membersCount, _membersSelector, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _NewGroup;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NewGroup = _exports.default = (_class = (_NewGroup = class NewGroup extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "newGroupTitle", _descriptor4, this);
      _defineProperty(this, "placeholder", _discourseI18n.default.t("chat.direct_message_creator.group_name"));
    }
    get membersCount() {
      return this.args.members?.reduce((acc1, member1) => {
        if (member1.type === "group") {
          return acc1 + member1.model.chat_enabled_user_count;
        } else {
          return acc1 + 1;
        }
      }, 1);
    }
    async createGroup() {
      try {
        const usernames1 = this.args.members.filter(member1 => member1.type === "user").mapBy("model.username");
        const groups1 = this.args.members.filter(member1 => member1.type === "group").mapBy("model.name");
        const channel1 = await this.chat.upsertDmChannel({
          usernames: usernames1,
          groups: groups1
        }, this.newGroupTitle);
        if (!channel1) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel1.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator__new-group-container">
        <div class="chat-message-creator__new-group">
          <div class="chat-message-creator__new-group-header-container">
            <div class="chat-message-creator__new-group-header">
              <Input
                name="channel-name"
                class="chat-message-creator__new-group-header__input"
                placeholder={{this.placeholder}}
                @value={{this.newGroupTitle}}
              />
  
              <MembersCount
                @count={{this.membersCount}}
                @max={{this.siteSettings.chat_max_direct_message_users}}
              />
            </div>
          </div>
  
          <MembersSelector
            @members={{@members}}
            @channel={{@channel}}
            @onChange={{@onChangeMembers}}
            @close={{@close}}
            @cancel={{@cancel}}
            @membersCount={{this.membersCount}}
            @maxReached={{gte
              this.membersCount
              this.siteSettings.chat_max_direct_message_users
            }}
          />
  
          {{#if @members.length}}
            <div class="chat-message-creator__new-group-footer-container">
              <div class="chat-message-creator__new-group-footer">
                <DButton
                  class="btn-primary btn-flat"
                  @label="cancel"
                  @action={{@cancel}}
                />
                <DButton
                  class="btn-primary create-chat-group"
                  @label="chat.new_message_modal.create_new_group_chat"
                  @action={{this.createGroup}}
                />
  
              </div>
            </div>
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "UqfhWeHr",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__new-group-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__new-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__new-group-header-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-message-creator__new-group-header\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,3,\"channel-name\"],[24,0,\"chat-message-creator__new-group-header__input\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\"],[[30,0,[\"newGroupTitle\"]]]],null],[1,\"\\n\\n            \"],[8,[32,1],null,[[\"@count\",\"@max\"],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@members\",\"@channel\",\"@onChange\",\"@close\",\"@cancel\",\"@membersCount\",\"@maxReached\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,0,[\"membersCount\"]],[28,[32,3],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__new-group-footer-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-message-creator__new-group-footer\"],[12],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,5]]],null],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary create-chat-group\"]],[[\"@label\",\"@action\"],[\"chat.new_message_modal.create_new_group_chat\",[30,0,[\"createGroup\"]]]],null],[1,\"\\n\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@members\",\"@channel\",\"@onChangeMembers\",\"@close\",\"@cancel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/new-group.js",
    "scope": () => [_component2.Input, _membersCount.default, _membersSelector.default, _truthHelpers.gte, _dButton.default],
    "isStrictMode": true
  }), _NewGroup), _NewGroup), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newGroupTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createGroup"), _class.prototype)), _class);
});