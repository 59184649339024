define("discourse/plugins/chat/discourse/services/chat-channel-notification-sound", ["exports", "@ember/service", "discourse/lib/desktop-notifications"], function (_exports, _service, _desktopNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChatChannelNotificationSound = _exports.default = (_class = class ChatChannelNotificationSound extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatAudioManager", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
    }
    async play(channel) {
      if (!(0, _desktopNotifications.canUserReceiveNotifications)(this.currentUser)) {
        return false;
      }
      if (channel.isCategoryChannel) {
        return false;
      }
      if (channel.chatable.group) {
        return false;
      }
      if (!this.currentUser.chat_sound) {
        return false;
      }
      if (this.site.mobileView) {
        return false;
      }
      const membership = channel.currentUserMembership;
      if (!membership.following) {
        return false;
      }
      if (membership.desktopNotificationLevel !== "always") {
        return false;
      }
      if (membership.muted) {
        return false;
      }
      if (this.chat.activeChannel === channel) {
        return false;
      }
      await this.chatAudioManager.play(this.currentUser.chat_sound);
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatAudioManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});