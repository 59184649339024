define("discourse/plugins/chat/discourse/services/chat", ["exports", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/user-presence", "discourse-common/lib/deprecated", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _tracking, _object, _computed, _runloop, _service, _ajax, _ajaxError, _userPresence, _deprecated, _later, _decorators, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CHAT_ONLINE_OPTIONS = {
    userUnseenTime: 300000,
    // 5 minutes seconds with no interaction
    browserHiddenTime: 300000 // Or the browser has been in the background for 5 minutes
  };
  let Chat = _exports.default = (_dec = (0, _computed.and)("currentUser.has_chat_enabled", "siteSettings.chat_enabled"), _dec2 = (0, _object.computed)("currentUser.staff", "currentUser.groups.[]"), _dec3 = (0, _object.computed)("chatChannelsManager.directMessageChannels"), _dec4 = (0, _object.computed)("activeChannel.userSilenced"), (_class = class Chat extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "chatNotificationManager", _descriptor4, this);
      _initializerDefineProperty(this, "chatSubscriptionsManager", _descriptor5, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor6, this);
      _initializerDefineProperty(this, "chatDraftsManager", _descriptor7, this);
      _initializerDefineProperty(this, "presence", _descriptor8, this);
      _initializerDefineProperty(this, "router", _descriptor9, this);
      _initializerDefineProperty(this, "site", _descriptor10, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor11, this);
      _initializerDefineProperty(this, "chatTrackingStateManager", _descriptor12, this);
      _defineProperty(this, "cook", null);
      _defineProperty(this, "presenceChannel", null);
      _defineProperty(this, "sidebarActive", false);
      _defineProperty(this, "isNetworkUnreliable", false);
      _initializerDefineProperty(this, "userCanChat", _descriptor13, this);
      _initializerDefineProperty(this, "_activeMessage", _descriptor14, this);
      _initializerDefineProperty(this, "_activeChannel", _descriptor15, this);
    }
    get activeChannel() {
      return this._activeChannel;
    }
    set activeChannel(channel) {
      if (!channel) {
        this._activeMessage = null;
      }
      if (this._activeChannel) {
        this._activeChannel.activeThread = null;
      }
      this._activeChannel = channel;
    }
    get userCanDirectMessage() {
      if (!this.currentUser) {
        return false;
      }
      return this.currentUser.staff || this.currentUser.can_direct_message;
    }
    get userHasDirectMessages() {
      return this.chatChannelsManager.directMessageChannels?.length > 0;
    }
    get userCanAccessDirectMessages() {
      return this.userCanDirectMessage || this.userHasDirectMessages;
    }
    get userCanInteractWithChat() {
      return !this.activeChannel?.userSilenced;
    }
    get activeMessage() {
      return this._activeMessage;
    }
    set activeMessage(hash) {
      if (hash) {
        this._activeMessage = hash;
      } else {
        this._activeMessage = null;
      }
    }
    init() {
      super.init(...arguments);
      if (this.userCanChat) {
        this.presenceChannel = this.presence.getChannel("/chat/online");
        (0, _userPresence.onPresenceChange)({
          callback: this.onPresenceChangeCallback,
          browserHiddenTime: 150000,
          userUnseenTime: 150000
        });
      }
    }
    onPresenceChangeCallback(present) {
      if (present) {
        // NOTE: channels is more than a simple array, it also contains
        // tracking and membership data, see Chat::StructuredChannelSerializer
        this.chatApi.listCurrentUserChannels().then(channelsView => {
          this.chatSubscriptionsManager.stopChannelsSubscriptions();
          this.chatSubscriptionsManager.startChannelsSubscriptions(channelsView.meta.message_bus_last_ids);
          [...channelsView.public_channels, ...channelsView.direct_message_channels].forEach(channelObject => {
            this.chatChannelsManager.find(channelObject.id, {
              fetchIfNotFound: false
            }).then(channel => {
              if (!channel) {
                return;
              }
              // TODO (martin) We need to do something here for thread tracking
              // state as well on presence change, otherwise we will be back in
              // the same place as the channels were.
              //
              // At some point it would likely be better to just fetch an
              // endpoint that gives you all channel tracking state and the
              // thread tracking state for the current channel.

              // ensures we have the latest message bus ids
              channel.meta.message_bus_last_ids = channelObject.meta.message_bus_last_ids;
              const state = channelsView.tracking.channel_tracking[channel.id];
              channel.tracking.unreadCount = state.unread_count;
              channel.tracking.mentionCount = state.mention_count;
              channel.currentUserMembership = channelObject.current_user_membership;
              this.chatSubscriptionsManager.startChannelSubscription(channel);
            });
          });
        });
      }
    }
    markNetworkAsUnreliable() {
      (0, _runloop.cancel)(this._networkCheckHandler);
      this.set("isNetworkUnreliable", true);
      this._networkCheckHandler = (0, _later.default)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.markNetworkAsReliable();
      }, 30000);
    }
    markNetworkAsReliable() {
      (0, _runloop.cancel)(this._networkCheckHandler);
      this.set("isNetworkUnreliable", false);
    }
    async loadChannels() {
      // We want to be able to call this method multiple times, but only
      // actually load the channels once. This is because we might call
      // this method before the chat is fully initialized, and we don't
      // want to load the channels multiple times in that case.
      try {
        if (this.chatStateManager.hasPreloadedChannels) {
          return;
        }
        if (this.loadingChannels) {
          return this.loadingChannels;
        }
        this.loadingChannels = new Promise(resolve => {
          this.chatApi.listCurrentUserChannels().then(result => {
            this.setupWithPreloadedChannels(result);
            this.chatStateManager.hasPreloadedChannels = true;
            resolve();
          });
        });
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    setupWithPreloadedChannels(channelsView) {
      this.chatSubscriptionsManager.startChannelsSubscriptions(channelsView.meta.message_bus_last_ids);
      this.presenceChannel.subscribe(channelsView.global_presence_channel_state);
      [...channelsView.public_channels, ...channelsView.direct_message_channels].forEach(channelObject => {
        const storedChannel = this.chatChannelsManager.store(channelObject);
        const storedDrafts = (this.currentUser?.chat_drafts || []).filter(draft => draft.channel_id === storedChannel.id);
        storedDrafts.forEach(storedDraft => {
          this.chatDraftsManager.add(_chatMessage.default.createDraftMessage(storedChannel, Object.assign({
            user: this.currentUser
          }, JSON.parse(storedDraft.data))), storedDraft.channel_id, storedDraft.thread_id);
        });
        if (channelsView.unread_thread_overview?.[storedChannel.id]) {
          storedChannel.threadsManager.unreadThreadOverview = channelsView.unread_thread_overview[storedChannel.id];
        }
        return this.chatChannelsManager.follow(storedChannel);
      });
      this.chatTrackingStateManager.setupWithPreloadedState(channelsView.tracking);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.userCanChat) {
        this.chatSubscriptionsManager.stopChannelsSubscriptions();
        (0, _userPresence.removeOnPresenceChange)(this.onPresenceChangeCallback);
      }
    }
    updatePresence() {
      (0, _runloop.next)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        if (this.currentUser.user_option?.hide_profile_and_presence) {
          return;
        }
        if (this.chatStateManager.isActive) {
          this.presenceChannel.enter({
            activeOptions: CHAT_ONLINE_OPTIONS
          });
        } else {
          this.presenceChannel.leave();
        }
      });
    }
    getDocumentTitleCount() {
      return this.chatNotificationManager.shouldCountChatInDocTitle() ? this.chatTrackingStateManager.allChannelUrgentCount : 0;
    }
    switchChannelUpOrDown(direction) {
      const {
        activeChannel
      } = this;
      if (!activeChannel) {
        return; // Chat isn't open. Return and do nothing!
      }
      let currentList, otherList;
      if (activeChannel.isDirectMessageChannel) {
        currentList = this.chatChannelsManager.truncatedDirectMessageChannels;
        otherList = this.chatChannelsManager.publicMessageChannels;
      } else {
        currentList = this.chatChannelsManager.publicMessageChannels;
        otherList = this.chatChannelsManager.truncatedDirectMessageChannels;
      }
      const directionUp = direction === "up";
      const currentChannelIndex = currentList.findIndex(c => c.id === activeChannel.id);
      let nextChannelInSameList = currentList[currentChannelIndex + (directionUp ? -1 : 1)];
      if (nextChannelInSameList) {
        // You're navigating in the same list of channels, just use index +- 1
        return this.router.transitionTo("chat.channel", ...nextChannelInSameList.routeModels);
      }

      // You need to go to the next list of channels, if it exists.
      const nextList = otherList.length ? otherList : currentList;
      const nextChannel = directionUp ? nextList[nextList.length - 1] : nextList[0];
      if (nextChannel.id !== activeChannel.id) {
        return this.router.transitionTo("chat.channel", ...nextChannel.routeModels);
      }
    }
    _fireOpenFloatAppEvent(channel) {
      let messageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      messageId ? this.router.transitionTo("chat.channel.near-message", ...channel.routeModels, messageId) : this.router.transitionTo("chat.channel", ...channel.routeModels);
    }
    async followChannel(channel) {
      return this.chatChannelsManager.follow(channel);
    }
    async unfollowChannel(channel) {
      return this.chatChannelsManager.unfollow(channel).then(() => {
        if (channel === this.activeChannel && channel.isDirectMessageChannel) {
          this.router.transitionTo("chat");
        }
      });
    }
    upsertDmChannelForUser(channel, user) {
      const usernames = (channel.chatable.users || []).mapBy("username").concat(user.username).uniq();
      return this.upsertDmChannel({
        usernames
      });
    }

    // @param {object} targets - The targets to create or fetch the direct message
    // channel for. The current user will automatically be included in the channel when it is created.
    // @param {array} [targets.usernames] - The usernames to include in the direct message channel.
    // @param {array} [targets.groups] - The groups to include in the direct message channel.
    // @param {string|null} [name=null] - Optional name for the direct message channel.
    upsertDmChannel(targets) {
      let name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      return (0, _ajax.ajax)("/chat/api/direct-message-channels.json", {
        method: "POST",
        data: {
          target_usernames: targets.usernames?.uniq(),
          target_groups: targets.groups?.uniq(),
          name
        }
      }).then(response => {
        const channel = this.chatChannelsManager.store(response.channel);
        this.chatChannelsManager.follow(channel);
        return channel;
      }).catch(_ajaxError.popupAjaxError);
    }

    // @param {array} usernames - The usernames to fetch the direct message
    // channel for. The current user will automatically be included as a
    // participant to fetch the channel for.
    getDmChannelForUsernames(usernames) {
      return (0, _ajax.ajax)("/chat/direct_messages.json", {
        data: {
          usernames: usernames.uniq().join(",")
        }
      });
    }
    addToolbarButton() {
      (0, _deprecated.default)("Use the new chat API `api.registerChatComposerButton` instead of `chat.addToolbarButton`");
    }
    toggleDrawer() {
      this.chatStateManager.didToggleDrawer();
      this.appEvents.trigger("chat:toggle-expand", this.chatStateManager.isDrawerExpanded);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatNotificationManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatSubscriptionsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "chatDraftsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "chatTrackingStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "userCanChat", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_activeMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_activeChannel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "userCanDirectMessage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "userCanDirectMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userHasDirectMessages", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "userHasDirectMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userCanInteractWithChat", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "userCanInteractWithChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPresenceChangeCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onPresenceChangeCallback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDrawer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDrawer"), _class.prototype)), _class));
});